
/**
 * Validation of Institutionskennung
 * @param value
 * @returns {boolean}
 */
const digits = "0123456789";
const upper = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
const lower = "abcdefghijklmnopqrstuvwxyz"
const specials = "~!@#$%^&*()-_=+[]{};:,.<>/?§€"
const passwordContainsDigit = (value = "") => {
    for (let v of value) {
        if (digits.includes(v))
            return true;
    }
    return false;
}

const passwordContainsUpper = (value = "") => {
    for (let v of value) {
        if (upper.includes(v))
            return true;
    }
    return false;
}

const passwordContainsLower = (value = "") => {
    for (let v of value) {
        if (lower.includes(v))
            return true;
    }
    return false;
}

const passwordContainsSpecial = (value = "") => {
    for (let v of value) {
        if (specials.includes(v))
            return true;
    }
    return false;
}

const passwordOnlyValidChars = (value = "") => {
    const vals = digits + upper + lower + specials
    for (let v of value) {
        if (vals.includes(v)) {
            continue;
        }
        return false;
    }
    return true;
}

export {specials, lower, digits, upper}
export {passwordContainsDigit, passwordContainsLower, passwordContainsSpecial, passwordContainsUpper, passwordOnlyValidChars}
