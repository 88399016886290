<template>
    <div class="container md-layout md-alignment-top-center">
        <form v-if="mode === MODES.FORM"
              class="md-layout-item md-xlarge-size-50 md-large-size-50 md-size-50 md-small-size-80 md-xsmall-size-90" novalidate
              @submit.prevent="validateInputs">
            <md-card>
                <md-card-header>
                    <div class="md-title">
                        Passwort ändern
                    </div>
                </md-card-header>

                <md-card-content>
                    <div class="md-layout md-gutter">
                        <div class="md-layout-item md-small-size-100">
                            <md-field :class="getValidationClass('password')">
                                <label for="password">Passwort</label>
                                <md-input id="password" v-model="form.password" :disabled="sending"
                                          autocomplete="new-password"
                                          name="password" type="password"/>
                                <span v-if="!$v.form.password.required" class="md-error">
                                        Es muss ein Passwort angegeben werden!
                                    </span>
                                <span v-else-if="!$v.form.password.minLength" class="md-error">
                                        Zu kurzes Passwort!
                                    </span>
                                <span v-else-if="!$v.form.password.passwordContainsDigit" class="md-error">
                                        Es fehlt eine Zahl!
                                    </span>
                                <span v-else-if="!$v.form.password.passwordContainsUpper" class="md-error">
                                        Es fehlt eine Großbuchstabe!
                                    </span>
                                <span v-else-if="!$v.form.password.passwordContainsLower" class="md-error">
                                        Es fehlt eine Kleinbuchstabe!
                                    </span>
                                <span v-else-if="!$v.form.password.passwordContainsSpecial" class="md-error">
                                        Es fehlt ein Sonderzeichen!
                                    </span>
                                <span v-else-if="!$v.form.password.passwordOnlyValidChars" class="md-error">
                                        Ungültiges Zeichen!
                                    </span>
                            </md-field>
                        </div>

                        <div class="md-layout-item md-small-size-100">
                            <md-field :class="getValidationClass('confirmPassword')">
                                <label for="confirmPassword">Passwort wiederholen</label>
                                <md-input id="confirmPassword" v-model="form.confirmPassword" :disabled="sending"
                                          autocomplete="new-password" name="confirmPassword" type="password"/>
                                <span v-if="!$v.form.confirmPassword.required" class="md-error">
                                        Das Passwort muss hier wiederholt werden!
                                    </span>
                                <span v-else-if="!$v.form.confirmPassword.sameAs" class="md-error">
                                        Die beiden Passwörter müssen übereinstimmen!
                                    </span>
                            </md-field>
                        </div>

                        <div class="md-layout-item md-size-100">
                            Das Passwort muss einen Groß- und einen Kleinbuchstaben, sowie eine Zahl und ein Sonderzeichen
                            enthalten. Es muss mindestens 6 Zeichen lang sein.
                            <br>
                            <div v-if="$v.$invalid && $v.form.password.$dirty && !$v.form.password.passwordOnlyValidChars"
                                 :class="getValidationClass('password')">
                                    <span class="md-error">
                                        Erlaubte Sonderzeichen: <code>{{ specials }}</code>
                                    </span>
                            </div>
                        </div>
                    </div>
                </md-card-content>
                <md-card-actions>
                    <md-button :disabled="sending" class="md-primary md-raised" type="submit">Passwort speichern</md-button>
                </md-card-actions>
            </md-card>
        </form>

        <md-card v-if="mode === MODES.SUCCESS"
                 class="md-layout-item md-xlarge-size-50 md-large-size-50 md-size-50 md-small-size-50 md-xsmall-size-90">
            <md-card-header>
                <div class="md-title">
                    Passwort ändern
                </div>
            </md-card-header>

            <md-card-content>
                Das Passwort wurde geändert
            </md-card-content>
        </md-card>

        <md-card v-if="mode === MODES.FAILED"
                 class="md-layout-item md-xlarge-size-50 md-large-size-50 md-size-50 md-small-size-50 md-xsmall-size-90">
            <md-card-header>
                <div class="md-title">
                    FEHLER
                </div>
            </md-card-header>

            <md-card-content>
                Leider ist ein Fehler aufgetreten. Bitte versuche es später noch einmal.
            </md-card-content>
        </md-card>

        <md-card v-if="mode === MODES.INVALID_LINK"
                 class="md-layout-item md-xlarge-size-50 md-large-size-50 md-size-50 md-small-size-50 md-xsmall-size-90">
            <md-card-header>
                <div class="md-title">
                    FEHLER
                </div>
            </md-card-header>

            <md-card-content>
                Der eingegebene Link ist ungültig.
            </md-card-content>
        </md-card>
    </div>
</template>

<script>
import {minLength, required, sameAs} from 'vuelidate/lib/validators'
import {specials} from '@/helpers/passwordConstraints'
import {passwordContainsDigit, passwordContainsLower, passwordContainsSpecial, passwordContainsUpper, passwordOnlyValidChars} from '@/helpers/passwordConstraints'

const MODES = {
    FORM: 0,
    SUCCESS: 1,
    INVALID_LINK: 8,
    FAILED: 9
}

export default {
    name: 'All_SetNewPassword',
    data: () => ({
        mode: 0,
        form: {
            password: '',
            confirmPassword: ''
        },
        MODES,
        specials
    }),
    mounted() {
        const t = this;
        const key_enc = this.$route.params.key
        const mail_enc = this.$route.params.mail
        let key = undefined
        let mail = undefined
        if(key_enc) {
            key = decodeURIComponent(key_enc)
        }
        if(mail_enc) {
            mail = decodeURIComponent(mail_enc)
        }
        if (key && key !== "" && mail && mail !== "") {
            t.mode = MODES.FORM
        } else {
            t.mode = MODES.INVALID_LINK
        }
    },
    computed: {
        sending: function () {
            return this.$store.state.rxtx > 0
        },
        key: function () {
            return decodeURIComponent(this.$route.params.key)
        },
        email: function () {
            return decodeURIComponent(this.$route.params.mail)
        }
    },
    methods: {
        getValidationClass(fieldName) {
            const field = this.$v.form[fieldName];
            if (field) { return { 'md-invalid': field.$invalid && field.$dirty } }
        },
        validateInputs() {
            this.$v.form.$touch();

            if (!this.$v.$invalid) {
                const req = {
                    email: this.email,
                    password: this.form.password,
                    confirmPassword: this.form.confirmPassword,
                    token: this.key
                }
                this.$store.dispatch("requestPasswordChange", req).then(() => {
                    this.$v.$reset();
                    this.mode = MODES.SUCCESS
                    console.log("password change done")
                }).catch(() => {
                    this.mode = MODES.FAILED
                    this.$store.dispatch("showSnackbar", {text: "Es ist ein Fehler aufgetreten."})
                })
            } else {
                // do nothing, user feedback will be displayed
            }
        },
    },
    validations() {
        return {
            form: {
                password: {
                    required,
                    minLength: minLength(6),
                    passwordContainsDigit: passwordContainsDigit,
                    passwordContainsUpper: passwordContainsUpper,
                    passwordContainsLower: passwordContainsLower,
                    passwordContainsSpecial: passwordContainsSpecial,
                    passwordOnlyValidChars: passwordOnlyValidChars,
                },
                confirmPassword: {
                    required,
                    sameAs: sameAs('password')
                },
            }
        }
    },
}
</script>

<style lang="scss" scoped>
#container {
    background-color: #FFFFFF;
    border: 1px solid #CCCCCC;
    padding: 20px;
    margin-top: 10px;
}
</style>
